import React from "react";

import Herosection_men from "./Herosection_men";
import Herocards_men from "./Herocards_men";
const Blogs = () => {
  return (
    <div>
      <Herosection_men />
      <Herocards_men />
    </div>
  );
};

export default Blogs;
