import React from 'react'

export default function Whocanjoin() {
  return (
    <div className="my-4 lg:my-16" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

<h1 className='text-centered aimheading my-4 lg:my-16 mx-2'>
            Who can join community?
        </h1>
        
        <p style={{width:'80%',marginTop:'20px',fontSize:'20px',fontWeight:'500'}}>The community will be open for students from across the country – from School students pursuing 6th to 10th standards.</p>
      
    </div>
  )
}
